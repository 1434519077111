const ModalManager = {
  replace: (modalHtml) => {
      const tmpModal = document.createElement('div');
      tmpModal.innerHTML = modalHtml;
      const modalContent = tmpModal.getElementsByClassName('js-modal-content')[0].innerHTML;
      const modalTitle = tmpModal.getElementsByClassName('js-modal-title')[0].innerHTML;
      const modalContainerClasses = tmpModal.getElementsByClassName('js-modal-container')[0].className;
      document.getElementById('modal-1-content').innerHTML = modalContent;
      document.getElementById('modal-1-title').innerHTML = modalTitle;
      document.getElementById('modal-1-container').className = modalContainerClasses;            
    }
};

export default ModalManager;