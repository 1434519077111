import Rails from '@rails/ujs';

// works only for element with data-confirm attriubute
function railsConfirmModalTemplate(title, message, ok, cancel) {
  return `
    <div class="micromodal micromodal-slide is-open" id="modal-1" aria-hidden="false">
      <div class="micromodal__overlay" tabindex="-1" data-micromodal-close="" data-children-count="0">
        <div class="micromodal__container is-small" role="dialog" aria-modal="true" aria-labelledby="modal-1-title">
          <header class="micromodal__header mb-8">
            <button class="micromodal__close" aria-label="Close modal" data-micromodal-close=""></button>
            <h2 class="micromodal__title" id="modal-1-title">
              ${title}
            </h2>
          </header>
          <div class="micromodal__content mb-8 text-base" id="modal-1-content">
            ${message}
          </div>
          <div class="micromodal__footer">
            <button type="button" id="confirm-ok" class="max-w-0 mx-auto mt-4 Btn-border-primary flex transition-color cursor-pointer">${ok}</button>
            <button aria-label="Close modal" data-micromodal-close="" class="max-w-0 mx-auto mt-4 Btn-primary flex transition-color cursor-pointer">${cancel}</button>
          </div>
        </div>
      </div>
    </div>
  `;
}

(function() {
  const handleConfirm = function(element) {
    if (!allowAction(this)) {
      Rails.stopEverything(element);
    }
  }

  const allowAction = function(element) {
    if (element.getAttribute('data-confirm') === null) {
      return true;
    }

    showConfirmationDialog(element);
    return false;
  }

  // Display the confirmation dialog
  const showConfirmationDialog = function(element) {
    const message = element.getAttribute('data-confirm');
    const ok = element.getAttribute('data-confirm-ok') || 'OK';
    const cancel = element.getAttribute('data-confirm-cancel') || 'Annuler';
    const title = element.getAttribute('data-confirm-title') || 'Attention';

    const html = railsConfirmModalTemplate(title, message, ok, cancel);

    const modalContainer = document.getElementById('modal-container');
    modalContainer.innerHTML = html;
    microModalShow();
    const confirmBtn = document.querySelector('#modal-1 #confirm-ok');
    confirmBtn.addEventListener('click', () => { confirmed(element) });
  }

  const confirmed = function(element) {
    // User clicked confirm button
    microModalClose();
    const dataConfirmAttr = element.getAttribute('data-confirm');
    element.removeAttribute('data-confirm');
    element.click();
    element.setAttribute('data-confirm', dataConfirmAttr);
  }

  // Hook the event before the other rails events so it works togeter
  // with `method: :delete`.
  // See https://github.com/rails/rails/blob/master/actionview/app/assets/javascripts/rails-ujs/start.coffee#L69
  Rails.delegate(document, 'a[data-confirm]', 'click', handleConfirm);
}).call(this)

// works when manually trigger confirm calling Rails.confirm in js code
// see https://github.com/rails/rails/pull/32404 for explanations about this function below

Rails.confirm = function(message, element, callback) {
  let title = 'Confirmez votre choix';
  let ok = 'OK';
  let cancel = 'Annuler';
  if(element){
    ok = element.getAttribute('data-confirm-ok') || ok;
    cancel = element.getAttribute('data-confirm-cancel') || cancel;
  }

  const html = railsConfirmModalTemplate(title, message, ok, cancel);

  const modalContainer = document.getElementById('modal-container');
  modalContainer.innerHTML = html;
  microModalShow();
  const confirmBtn = document.querySelector("#modal-1 #confirm-ok");
  confirmBtn.addEventListener('click', (event) => {
    event.preventDefault();
    microModalClose();
    let old = Rails.confirm

    Rails.confirm = function() { return true }

    if(typeof element !== 'undefined') {
      element.click();
    } else {
      if(callback){
        callback()
      }
    }

    Rails.confirm = old;
  });

  return false;
}