import { Controller } from "stimulus";
import { useClickOutside } from 'stimulus-use'

export default class extends Controller {

  static targets = [ "menu" ];

  connect() {
    useClickOutside(this);
  }
  
  toggleMenu() {
    this.menuTarget.classList.toggle('hidden');
  }

  clickOutside() {
    this.menuTarget.classList.add('hidden');
  }
};