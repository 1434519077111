import { Controller } from "stimulus";

// ALERT: controller copied in vendor/assets/javascripts/spree/frontend/all.js
export default class extends Controller {

  static classes = [ "open" ];
  static values = { scroll: Boolean };

  scrollValueChanged() {
    this.scrollValue
      ? document.body.classList.add('fixed')
      : document.body.classList.remove('fixed');
  };

  toggleMenu() {
    this.scrollValue = !this.scrollValue;
    this.element.classList.toggle(this.openClass);
  };
};