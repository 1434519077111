import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = [ "remainder", "textarea" ];

  connect() {
    this.countdown();
  }

  countdown() {
    const remainder = this.textareaTarget.getAttribute('maxLength') - this.textareaTarget.value.length
    this.remainderTarget.innerText = `(${remainder} caractère${remainder > 1 ? 's' : ''} restant${remainder > 1 ? 's' : ''})`;
  }

}