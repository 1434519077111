import { Controller } from "stimulus";
import IMask from 'imask';

export default class extends Controller {

  connect() {
    const hourMinutes = this.element.value.split(':');

    this.mask = IMask(this.element, {
      // mask: Date,
      // min: minDate,
      // max: maxDate,
      mask: 'hh:mm',  // Pattern mask with defined blocks, default is 'd{.}`m{.}`Y'
      // you can provide your own blocks definitions, default blocks for date mask are:
      blocks: {
        hh: {
          mask: IMask.MaskedEnum,
          enum: ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'],
          placeholderChar: 'h',
        },
        mm: {
          mask: IMask.MaskedEnum,
          enum: ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '47', '48', '49', '50', '51', '52', '53', '54', '55', '56', '57', '58', '59'],
          placeholderChar: 'm',
        },
      },
      lazy: false,
      autofix: true, // try to correct the entry if it does not meet the constraints
      overwrite: true, // can overwrite already entered date
    });
  } 

  disconnect() {
    if(this.mask) {
      this.mask.destroy();
    }
  }
}