import { Controller } from 'stimulus';

export default class extends Controller {

  static targets = [ "removeButton", "removeImageField", "image" ];

  removeImage(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    if (this.hasImageTarget) {
      this.removeImageFieldTarget.value = "1";
      this.hideImage();
    }
  }

  resetRemoveImageField(){
    this.removeImageFieldTarget.value = null;
  }

  hideImage() {
    if (this.hasImageTarget) {
      this.imageTarget.remove();
    }
    if (this.hasRemoveButtonTarget){
      this.removeButtonTarget.remove();
    }
  }
}