import { Controller } from "stimulus"
import * as Choices from 'choices.js';

export default class extends Controller {
  static targets = ['selectField']

  connect() {
    this.choicesElem = new Choices(this.selectFieldTarget, {
      itemSelectText: "",
      noResultsText: "Aucun résultat",
      sortFn: () => {},
      removeItems: true,
      removeItemButton: true,
    });
  }

  disconnect() {
    this.choicesElem.destroy();
  }
}
