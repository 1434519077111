
require("@rails/ujs").start();
require("turbolinks").start();

import * as ActiveStorage from "@rails/activestorage"
import "bundles/application/channels"

ActiveStorage.start()

import "bundles/application/index"

require.context('../svg', true);
require.context('../images', true);