import { Controller } from 'stimulus'
import { useIntersection } from 'stimulus-use'

export default class extends Controller {

  static targets = [ "scrollTrigger", "actionMenu" ];

  options = {
    element: this.actionMenuTarget,
  }

  connect() {
    useIntersection(this, this.options)
  }

  scroll(evt) {
    evt.preventDefault();
    this.actionMenuTarget.scrollIntoView();
  }

  appear(entry) {
    this.scrollTriggerTarget.classList.add('is-hidden');
  }
  
  disappear(entry) {
    this.scrollTriggerTarget.classList.remove('is-hidden');
  }
}