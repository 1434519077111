import { Controller } from 'stimulus';

export default class extends Controller {

  static targets = [ "status" ];
  static classes = [ "inactive"]

  onDragenter() {
    this.element.classList.add(this.inactiveClass);
  };
  onDragleave() {
    this.element.classList.remove(this.inactiveClass);
  };
  
  onChange(evt) {
    this.element.classList.remove(this.inactiveClass);

    if(!evt.target.files.length) {
      this.statusTarget.innerText = "Aucun fichier sélectionné";
    } else if(evt.target.files.length === 1) {
      this.statusTarget.innerText = '1 fichier sélectionné (Cliquez sur "Enregistrer mon projet" pour valider votre pièce jointe.)';
    } else {
      this.statusTarget.innerText = `${evt.target.files.length} fichiers sélectionnés (Cliquez sur "Enregistrer mon projet" pour valider vos pièces jointes.)`;
    }
  };

}