import { Controller } from "stimulus";
import Inputmask from "inputmask";

export default class extends Controller {

  phoneNumberInputMaskOptions = {
    regex: "[0-9]{2} [0-9]{2} [0-9]{2} [0-9]{2} [0-9]{2}",
    placeholder: "XX XX XX XX XX",
    removeMaskOnSubmit: true, // submit unmasked, raw values,
    showMaskOnHover: false,
    autoUnmask: true
  };

  connect() {
    // horrible fix https://github.com/stimulusjs/stimulus/issues/328
    setTimeout(() => {
      let phoneNumberMask = new Inputmask(this.phoneNumberInputMaskOptions);
      phoneNumberMask.mask(this.element);
      this.element.pattern = this.phoneNumberInputMaskOptions.regex;
    }, 0);
  };
};
