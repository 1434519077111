import { Controller } from "stimulus";
import { FLASH_AUTO_DISMISS_DELAY } from '../constants';

export default class extends Controller {
  static targets = [ "close" ];

  connect() {
    this.delayFadeFlash();
  } 

  makeInvisible($el) {
    $el.classList.add('is-invisible');
  }

  delayFadeFlash() {   
    const callback = () => {
      this.makeInvisible(this.element.parentElement);
    };
    setTimeout(()=>{
      callback();
    }, FLASH_AUTO_DISMISS_DELAY);
  }

  close() {
    this.makeInvisible(this.element.parentElement);
  }
}