window.onRecaptchaLoadCallback = () => {
  document.querySelectorAll(".g-recaptcha").forEach((e)=> {
    widgetId = grecaptcha.render(e);
    e.closest('form').dataset.recaptchaWidgetId = widgetId;
  })
}

window.submitContactForm = () => {
  document.getElementById("contact-form").submit();
};

window.submitRegistrationsNewForm = () => {
  document.getElementById("registrations-new").submit();
};