import { Controller } from "stimulus"
import * as Choices from 'choices.js';
import debounce from 'lodash/debounce';


export default class extends Controller {
  static targets = ['selectField']

  connect() {
    this.choicesElem = new Choices(this.selectFieldTarget, {
      noChoicesText: "Aucun résultat",
      searchChoices: false,
      itemSelectText: "",
      searchFloor: 2,
      sortFn: () => {},
    });

    this.choicesElem.passedElement.element.addEventListener(
      'search',
      debounce(async e => {
        fetch(`/api/cities?q=${encodeURIComponent(e.detail.value)}`).then((response) => {
          response.json().then((data) => {
            this.choicesElem.setChoices(data.cities, 'id', 'name_with_zip_code', true)
          });
        })
        .catch((error) => {
          console.log(error);
        });
      }, 400)
    )
  }



  disconnect() {
    this.choicesElem.destroy();
  }
}
