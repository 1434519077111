import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = [ "submitButton" ];

  connect() { }

  reportValidityOrExecuteRecaptcha() {
    if (this.element.checkValidity()) {
      grecaptcha.execute(this.element.dataset.recaptchaWidgetId);
    }
    else {
      this.element.reportValidity();
    }
  }
}