import './stylesheets/index';
import './controllers';
import './scripts/turbolinks_utils';
import MicroModal from 'micromodal';
import ModalManager from './scripts/modal_manager';
import './scripts/recaptcha';

window.MicroModal = MicroModal;

window.microModalShow = (args) => {
  MicroModal.show('modal-1', Object.assign({
    onShow: (modal) => {
      const elementToFocus = modal.querySelector('[autofocus="autofocus"]');
      if (elementToFocus) {
        elementToFocus.focus();
      }
    }
  }, args));
}

window.microModalClose = () => {
  MicroModal.close('modal-1');
}

window.ModalManager = ModalManager;

import './scripts/confirm_dialog';