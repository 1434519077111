import { Controller } from 'stimulus'

export default class extends Controller {

  open(evt) {
    evt.preventDefault();
    const targetId = evt.target.getAttribute('data-id');
    document.getElementById(targetId).scrollIntoView();
    document.getElementById(targetId).open = true;
  }
  
}