import { Controller } from 'stimulus';
import lightGallery from 'lightgallery';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import 'lightgallery/scss/lightgallery';
import 'lightgallery/scss/lg-thumbnail';


export default class extends Controller {

  connect() {
    this.lightGallery = lightGallery(this.element, {
      licenseKey: window.secrets.lightGalleryLicenseKey,
      plugins: [lgThumbnail],
      thumbnail: true,
      selector: '.lg-item',
      addClass: 'lg-custom-thumbnail'
    });

    this.element.addEventListener('lgAfterAppendSlide', () => {
      document.body.classList.add('overflow-y-hidden');
    });
    this.element.addEventListener('lgBeforeClose', () => {
      document.body.classList.remove('overflow-y-hidden');
    });
  }

  disconnect() {
    this.lightGallery.destroy();
  }
}